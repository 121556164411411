@import "./Mixins.scss";

.cmsPage-layout {
    @media(max-width:899px) {
        padding-top: 110px;
    }

    h1 {
        @media(max-width: 899px) {
            font-size: 34px;
            line-height: 50px;
        }
    }

    h2 {
        font-size: 35px;
        line-height: 60px;
        font-weight: 400;

        @media(max-width: 899px) {
            font-size: 28px;
            line-height: 40px;
        }
    }

    h3 {
        @media(max-width: 899px) {
            font-size: 24px;
            line-height: 36px;
        }
    }

    h4 {
        font-size: 21px;
        line-height: 32px;
        text-align: center;
        font-weight: 400;
    }

    p {
        font-size: 16px;
        line-height: 28px;
        @media(max-width: 899px) {
            font-size: 14px;
            line-height: 22px;
        }
    }

    .mb-margin1 {
        @media(max-width:899px) {
            margin-bottom: 24px !important;
        }
    }

    .mb-margin2 {
        @media(max-width:899px) {
            margin-bottom: 40px !important;
        }
    }

    .bluedot_list {
        li {
            position: relative;
            padding-left: 12px;
            @include fontSize(16px);
            line-height: 28px;
            text-align: justify;

            &:before {
                content: '';
                @include position(absolute, $top: 10px, $left: 1px);
                background: $primary;
                height: 6px;
                width: 6px;
                border-radius: 1px;
                transform: rotate(45deg);
            }

            span {
                font-weight: 700;
                text-transform: uppercase;
            }
        }
    }

    .contentPages-layout {
        padding-top: 40px;
        margin-bottom: 40px;

        @media(max-width:899px) {
            padding-top: 24px;
        }

        h2 {
            color: $primary;
            text-align: center;
        }

        p {
            text-align: justify;

            a {
                color: $primary;
                display: inline-block;
            }
        }

        h3 {
            color: $secondary;
            text-align: center;
        }

        .disclaimerPage {
            h4 {
                text-align: left;
                font-weight: 400;
            }
        }

        .numericList {
            li {
                @include fontSize(16px);
                line-height: 28px;
                margin-bottom: 8px;
                text-align: justify;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .pp_listpoint {
            li {
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }

                h4 {
                    margin-bottom: 8px;
                }
            }
        }

        .faqbox {
            outline: 1px solid $secondary;
            border-radius: 8px;
            overflow: hidden;

            .headerBox {
                padding: 4px 16px;
                background: linear-gradient(91.05deg, #0c4d85 0%, #775091 26.82%, #b95380 50.78%, #db6b5e 74.74%, #d89641 100%);
                border-radius: 8px 8px 0 0;

                h2 {
                    color: $white;
                    text-align: left;

                    @media(max-width:899px) {
                        font-size: 24px;
                        line-height: 36px;
                    }

                }
            }

            .contentBox {
                padding: 16px;

                p {
                    text-align: justify;
                }
            }
        }

        // claimPage css
        .productTabs-section {
            display: flex;
            gap: 24px;
            flex-wrap: wrap;

            .product_claim_tab {
                padding: 16px;
                display: block;
                width: calc(50% - 12px);
                border-radius: 16px;

                @media(max-width:899px) {
                    width: 100%;
                    padding: 12px;
                }

                &.active {
                    box-shadow: 0 -4px 10px 0 rgba(0, 0, 0, 0.25);
                }

                .productTag_box {
                    display: flex;
                    gap: 16px;
                    align-items: center;

                    span {
                        font-size: 21px;
                        line-height: 26px;
                        font-family: 'Signika';
                        color: $black;
                    }
                }

                p {
                    color: $black;
                    margin-bottom: 12px;
                }

                a {
                    color: $primary;
                }
            }
        }

        .gradientBox {
            padding: 4px 16px;
            background: linear-gradient(91.05deg, #0c4d85 0%, #775091 26.82%, #b95380 50.78%, #db6b5e 74.74%, #d89641 100%);
            border-radius: 8px;
            display: flex;
            gap: 16px;
            align-items: center;

            h2 {
                color: $white;
                text-align: left;

                @media(max-width:899px) {
                    font-size: 24px;
                    line-height: 36px;
                }

            }


        }

        .claimTypeTabs {
            .MuiTabs-root {
                .MuiTabs-scroller {
                    text-align: center;
                }

                .MuiTabs-flexContainer {
                    border-radius: 4px;
                    justify-content: center;
                    background: $white;
                    padding: 3px;
                    border: none;

                    @media(max-width:899px) {
                        border: 1px solid $secondary;
                        margin-top: 12px;
                        gap: 3px;
                        flex-wrap: wrap;
                    }

                    button {
                        font-family: 'Signika';
                        font-size: 15px;
                        line-height: 15px;
                        font-weight: 400;
                        padding: 12px 20px;
                        border-radius: 4px;

                        &.Mui-selected {
                            background: $secondary;
                        }

                        @media(max-width:899px) {
                            font-size: 13px;
                            line-height: 13px;
                            min-width: 50px;
                            border-radius: 4px;
                            padding: 12px 8px;
                        }
                    }
                }

            }

            .MuiTabPanel-root {
                padding-top: 24px !important;

                p {
                    text-align: justify;
                }

                h3 {
                    color: $secondary;
                    text-align: left;
                }

                h4 {
                    font-size: 21px;
                    line-height: 26px;
                    font-weight: 400;
                    text-align: left;
                }
            }
        }

        .claim_form_box {
            border: 1px solid $secondary;
            border-radius: 16px;
            padding: 24px;
            max-width: 792px;
            margin: auto;
            text-align: center;

            @media(max-width:899px) {
                padding: 12px;
            }

            h3 {
                color: $secondary;
            }

            p {
                text-align: center;
            }
        }
    }

    .aboutPage-layout {
        h1 {
            text-align: center;
        }

        h2 {
            color: $primary;
            text-align: center;
        }

        p {
            text-align: justify;

            a {
                color: $primary;
                display: inline-block;
            }
        }

        h3 {
            color: $secondary;
            text-align: center;
        }

        .about_header {
            background: url("./../../public/images/aboutus_makerbhawan.svg")no-repeat center center/cover;
            height: 500px;
            margin-bottom: 80px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media(max-width:899px) {
                height: 155px;
            }

            h1,
            h4 {
                color: $white;
                text-align: center;
            }

            h4 {
                @media(max-width:899px) {
                    font-size: 16px;
                    line-height: 28px;
                    font-family: 'Roboto';
                }
            }
        }

        .abt-grp {
            margin-bottom: 80px;

            p {
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .vision_box {
            background: #e7edf3;
            border: 1px solid $primary;
            border-radius: 16px;
            padding: 24px;

            @media(max-width:899px) {
                padding: 12px;
            }

            h1 {
                @media(max-width:899px) {
                    margin-bottom: 8px !important;
                }
            }
        }

        .mission_box {
            @extend .vision_box;
            background: rgba(216, 150, 65, 0.1);

            .bluedot_list {
                li {
                    margin-bottom: 4px;

                    &:before {
                        top: 10px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .formcms-layout {
        padding-top: 60px;
        padding-bottom: 60px;
        position: relative;
        min-height: 700px;

        &.fl_complaint {
            &:after {
                background: url('./../../public/images/complaint-image.svg')no-repeat center top/cover;
            }
        }

        @media(max-width:1199px) {
            min-height: auto;
        }

        @media(max-width:899px) {
            padding-top: 40px;
            padding-bottom: 0;

            &:before,
            &:after {
                display: none;
            }
        }

        &:before {
            content: '';
            @include position(absolute, $top: 0, $right: 0);
            background: rgba($color: $primary, $alpha: 0.7);
            width: 50%;
            height: 100%;
            z-index: 9;
        }

        &::after {
            content: '';
            @include position(absolute, $top: 0, $right: 0);
            background: url('./../../public/images/contactus_main_image.png')no-repeat center top/cover;
            width: 50%;
            height: 100%;
            z-index: 3;
        }

        h2 {
            color: $primary;
        }

        .formBox {
            max-width: 384px;

            @media(max-width:899px) {
                margin-left: auto;
                margin-right: auto;

                h2 {
                    text-align: center;
                }
            }
        }

        .primaryBtn {
            button {
                font-family: 'Signika';
            }
        }

        .right_outer {
            display: flex;
            align-items: center;
            position: relative;

            &.ro_complaint {
                &::after {
                    background: url('./../../public/images/complaint-image.svg')no-repeat center center/cover;
                }
            }

            @media(max-width:899px) {
                min-height: 330px;
                padding: 12px;

                &:before {
                    content: '';
                    @include position(absolute, $top: 0, $right: 0);
                    background: rgba($color: $primary, $alpha: 0.7);
                    width: 100%;
                    height: 100%;
                    z-index: 9;
                }

                &::after {
                    content: '';
                    @include position(absolute, $top: 0, $right: 0);
                    background: url('./../../public/images/contactus_main_image.png')no-repeat center center/cover;
                    width: 100%;
                    height: 100%;
                    z-index: 3;
                }
            }

            .rightbox {
                z-index: 9;
                position: relative;
                max-width: 490px;
                margin-left: auto;

                @media(max-width:899px) {
                    max-width: 100%;
                    margin-left: 0;
                }

                h2 {
                    color: $white;

                    span {
                        color: $secondary;
                    }
                }

                p {
                    margin-bottom: 50px;
                    color: $white;
                }

                .contactDetails_list {
                    display: flex;
                    flex-direction: column;
                    gap: 30px;

                    @media(max-width:899px) {
                        gap: 20px;
                    }

                    li {
                        font-size: 16px;
                        line-height: 28px;
                        font-weight: 700;
                        color: $white;

                        a,
                        p {
                            display: inline-flex;
                            align-items: center;
                            gap: 16px;
                            font-size: 16px;
                            line-height: 28px;
                            font-weight: 700;
                            color: $white;

                            svg {
                                fill: $secondary;
                                width: 30px;
                                height: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}