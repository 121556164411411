// Color Variables
$primary: #0c4d85;
$secondary:#d89641;
$white: #ffffff;
$black: #000000;
$lightRed: #FFEFF0;
$lightGrey: #F1F1F1;
$darkGrey: rgba(#000, 0.65);
$red:#ff0000;


/*--------------Mixins------------------*/
@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin border-radius($radius: none) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin appearance() {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@mixin fontSize($size) {
  //font-size: $size;
  font-size: calculateRem($size);
}

@mixin position($position,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

// Margins
@for $i from 0 to 20 {
  .ml-#{$i} {
    margin-left: 4px * $i !important;
  }
  .mr-#{$i} {
    margin-right: 4px * $i !important;
  }
  .mt-#{$i} {
    margin-top: 4px * $i !important;
  }
  .mb-#{$i} {
    margin-bottom: 4px * $i !important;
  }
  .mx-#{$i} {
    margin-left: 4px * $i !important;
    margin-right: 4px * $i !important;
  }
  .my-#{$i} {
    margin-top: 4px * $i !important;
    margin-bottom: 4px * $i !important;
  }
  .m-#{$i} {
    margin-top: 4px * $i !important;
    margin-bottom: 4px * $i !important;
    margin-left: 4px * $i !important;
    margin-right: 4px * $i !important;
  }
}

// Paddings
@for $i from 0 to 20 {
  .pl-#{$i} {
    padding-left: 4px * $i !important;
  }
  .pr-#{$i} {
    padding-right: 4px * $i !important;
  }
  .pt-#{$i} {
    padding-top: 4px * $i !important;
  }
  .pb-#{$i} {
    padding-bottom: 4px * $i !important;
  }
  .px-#{$i} {
    padding-left: 4px * $i !important;
    padding-right: 4px * $i !important;
  }
  .py-#{$i} {
    padding-top: 4px * $i !important;
    padding-bottom: 4px * $i !important;
  }
  .p-#{$i} {
    margin-top: 4px * $i !important;
    margin-bottom: 4px * $i !important;
    margin-left: 4px * $i !important;
    margin-right: 4px * $i !important;
  }
}