@import "./Mixins.scss";

.productPage-layout {
    @media(max-width:899px) {
        padding-top: 114px;
    }

    h2 {
        font-size: 35px;
        line-height: 60px;
        font-weight: 400;

        @media(max-width: 899px) {
            font-size: 25px;
            line-height: 40px;
        }
    }

    p {
        font-size: 16px;
        line-height: 28px;
        @media(max-width: 899px) {
            font-size: 14px;
            line-height: 22px;
        }
    }

    .header-Form-section {
        padding-top: 48px;
        margin-bottom: 32px;

        @media(max-width: 899px) {
            padding-top: 30px;
        }

        .formBox {
            max-width: 792px;
            min-height: 323px;
            padding: 8px 24px 24px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
            border-radius: 16px;
            margin: auto;
            text-align: center;
            @media(max-width: 899px) {
                padding: 8px 8px 24px;
            }
            h3 {
                @media(max-width: 899px) {
                    font-size: 22px;
                    line-height: 28px;
                }
            }

            .subheading {
                font-size: 12px;
                line-height: 12px;
                font-weight: 400;
                margin: 0px 0px 12px;
            }

            hr {
                border: none;
                border-top: 1px solid #e3e3e3;
            }
        }
    }

    .benefits-section {
        margin-bottom: 32px;

        .benfitsbox {
            outline: 1px solid $secondary;
            border-radius: 8px;
            overflow: hidden;

            .headerBox {
                padding: 4px 16px;
                background: linear-gradient(91.05deg, #0c4d85 0%, #775091 26.82%, #b95380 50.78%, #db6b5e 74.74%, #d89641 100%);
                border-radius: 8px 8px 0 0;

                h2 {
                    color: $white;

                    @media(max-width:899px) {
                        font-size: 24px;
                        line-height: 36px;
                    }
                }
            }

            .contentBox {
                padding: 16px;

                p {
                    text-align: justify;
                }

                .bikeProduct-img {
                    width: 100%;
                    max-width: 248px;
                }

                .healthProduct-img {
                    width: 100%;
                    max-width: 218px;
                }

                .twBenefits-list {
                    li {
                        font-size: 16px;
                        line-height: 27px;
                        text-align: justify;
                        margin-bottom: 12px;
                        @media(max-width:615px) {
                            font-size: 14px;
                            line-height: 22px;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }

                        span {
                            font-weight: 600;
                            display: block;

                        }
                    }
                }

                .healthBenefits-list {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    gap: 24px;
                    @media(max-width:615px) {
                        gap: 12px;
                    }

                    li {
                        background: linear-gradient(90deg, #bf7d27 40%, transparent 40%), linear-gradient(90deg, #bf7d27 40%, transparent 40%), linear-gradient(0deg, #bf7d27 40%, transparent 40%), linear-gradient(0deg, #bf7d27 40%, transparent 40%);
                        background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
                        background-size: 17px 1px, 17px 1px, 1px 15px, 1px 15px;
                        background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0px;
                        border-radius: 16px;
                        padding: 16px 8px;
                        text-align: center;
                        width: calc(20% - 19.2px);
                        min-width: 170px;

                        @media(max-width:615px) {
                            min-width: 140px;
                        }

                        img {
                            width: 100px;
                            height: 100px;

                            @media(max-width:899px) {
                                width: 60px;
                                height: 60px;
                            }
                        }

                        p {
                            font-weight: 700;
                            margin-top: 20px;
                            text-align: center;

                            @media(max-width:899px) {
                                font-size: 14px;
                                line-height: 21px;
                            }
                        }
                    }
                }
            }
        }
    }

    .types-section {
        margin-bottom: 32px;

        .types-tabs {
            .MuiTabs-root {
                border-bottom: 1px solid $primary;

                @media(max-width:899px) {
                    border-bottom: none;
                }

                .MuiTabs-flexContainer {
                    width: 100%;
                    border: none;
                    border-radius: 0;

                    @media(max-width:899px) {
                        flex-wrap: wrap;
                        gap: 8px;
                    }

                    button {
                        flex: 1;
                        font-family: 'Roboto';
                        font-size: 16px;
                        line-height: 27px;
                        font-weight: 700;
                        padding: 12px;
                        border-radius: 8px 8px 0 0;
                        max-width: 100%;

                        @media(max-width:899px) {
                            font-size: 14px;
                            line-height: 20px;
                            min-width: 140px;
                            border: 1px solid $primary;
                            border-radius: 8px;
                        }
                    }
                }

            }

            .MuiTabPanel-root {
                margin-top: 15px;

                p {
                    text-align: justify;
                }
            }
        }
    }

    .features-section {
        margin-bottom: 32px;

        .featurebox {
            outline: 1px solid $secondary;
            border-radius: 8px;
            overflow: hidden;

            .headerBox {
                padding: 4px 16px;
                background: linear-gradient(91.05deg, #0c4d85 0%, #775091 26.82%, #b95380 50.78%, #db6b5e 74.74%, #d89641 100%);
                border-radius: 8px 8px 0 0;

                h2 {
                    color: $white;

                    @media(max-width:899px) {
                        font-size: 24px;
                        line-height: 36px;
                    }
                }
            }

            .contentBox {
                padding: 16px;

                .product-img {
                    width: 100%;
                    max-width: 368px;
                }
            }
        }
    }

    .blue-square-list {
        li {
            position: relative;
            font-size: 16px;
            line-height: 27px;
            padding-left: 12px;
            text-align: justify;
            margin-bottom: 8px;
            @media(max-width:899px) {
                font-size: 14px;
                line-height: 22px;
            }

            &:before {
                background: #0c4d85;
                content: "";
                position: absolute;
                top: 11px;
                left: 0px;
                width: 6px;
                height: 6px;
                transform: rotate(45deg);
            }

            ol {
                padding-left: 22px;

                li {
                    list-style-type: decimal;
                    padding-left: 0;

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    .addOns-section {
        margin-bottom: 32px;

        p {
            text-align: justify;
        }
    }

    .inclusionExclusion-section {
        margin-bottom: 60px;

        .inclusionbox {
            outline: 1px solid $secondary;
            border-radius: 8px;
            overflow: hidden;

            .headerBox {
                padding: 4px 16px;
                background: linear-gradient(91.05deg, #0c4d85 0%, #775091 26.82%, #b95380 50.78%, #db6b5e 74.74%, #d89641 100%);
                border-radius: 8px 8px 0 0;

                h2 {
                    color: $white;

                    @media(max-width:899px) {
                        font-size: 24px;
                        line-height: 36px;
                    }
                }
            }

            .contentBox {
                padding: 16px;

                .coverageTabs {
                    .MuiTabs-root {

                        .MuiTabs-scroller {
                            text-align: center;
                        }

                        .MuiTabs-flexContainer {
                            border: 1px solid $primary;
                            border-radius: 8px;
                            justify-content: center;

                            button {
                                font-family: 'Roboto';
                                font-size: 16px;
                                line-height: 27px;
                                font-weight: 600;
                                padding: 7px 20px;

                                @media(max-width:899px) {
                                    font-size: 13px;
                                    line-height: 24px;
                                    min-width: 140px;
                                    border-radius: 8px;
                                    padding: 7px;
                                }
                            }
                        }

                    }

                    .MuiTabPanel-root {
                        margin-top: 15px;

                        p {
                            text-align: justify;
                        }
                    }
                }

                .coverage-img-img {
                    width: 100%;
                    max-width: 268px;
                }
            }
        }
    }

    .health-clarifypoints-sec {
        margin-bottom: 60px;

        .clarifyHealth-img {
            max-width: 384px;
            width: 100%;
        }
    }
}