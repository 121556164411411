@import "./../../../../SCSS/Mixins.scss";

.mnavbar {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
    // min-height: 86px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: $white;
    z-index: 9999;

    a {
        color: $primary;
    }

    .blinker_a {
        animation: blinker 2s linear infinite;
    }

    @keyframes blinker {
        50% {
            opacity: 0;
        }
    }

    .logo-strip {
        background: $primary;
        padding: 12px 0px;
    }

    .hamburger-menu {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 29px;
        height: 26px;
        background: $secondary;
        border-radius: 4px;

        svg {
            width: 20px;
        }
    }
}

// sidemenu css
.MuiDrawer-root {
    .MuiPaper-root {
        min-width: 60%;
        max-width: 60%;
        z-index: 90;

        .menuWrapper {
            position: relative;
            min-height: 100vh;
            padding-top: 114px;

            .menu-section {
                padding: 12px 16px;

                ul {
                    li {
                        padding: 6px 0;
                       border-bottom: 1px solid $lightGrey;

                        .nav-link {
                            text-decoration: none;
                            color: #000;
                            @include fontSize(13px);
                            line-height: 28px;
                            font-weight: 400;
                            cursor: pointer;
                            padding: 2px 0px;
                            display: inline-flex;
                            align-items: center;
                            width: 100%;
                            justify-content: space-between;

                        }

                        .nav-link-inner{
                            font-size: 14px;
                            line-height: 21px;
                            margin-bottom: 4px;
                            display: block;
                            color: #000;
                        }

                        .MuiCollapse-root{
                            margin-top: 4px;
                        }
                    }
                }
            }
        }
    }
}

.MuiBackdrop-root {
    background: rgba(0, 0, 0, 0.15) !important;
}