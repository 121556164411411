@import "./../../../../SCSS/Mixins.scss";

.footer {
    padding-top: 48px;
    padding-bottom: 16px;
    background: $primary;

    .jb_footer_img {
        width: 100%;
        max-width: 282px;
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 12px;

        li {
            a {
                color: $white;
                font-size: 16px;
                line-height: 28px;
                display: inline-block;

                &:hover {
                    color: $secondary;
                }
            }

        }


    }

    p {
        color: $white;
        font-size: 16px;
        line-height: 28px;

        span {
            font-weight: 700;
        }

        a {
            color: $white;
            font-weight: 700;
            word-break: break-all;
        }
    }

    .social-icons {
        display: flex;
        gap: 10px;

        a {
            display: inline-block;
            svg{
                width: 25px;
                height: 25px;
            }
            
        }
    }
}