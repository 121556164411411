@import "./../../../../SCSS/Mixins.scss";

.mfooter {
    padding: 24px 12px;
    background: $primary;

    .jb_footer_img {
        width: 100%;
        max-width: 183px;
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 12px;

        li {
            a {
                color: $white;
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
                display: inline-block;

                &:hover {
                    color: $secondary;
                }
            }

        }
    }

    p {
        color: $white;
        font-size: 16px;
        line-height: 28px;

        span {
            font-weight: 700;
        }

        a {
            color: $white;
            font-weight: 700;
            word-break: break-all;
        }
    }

    .social-icons {
        display: flex;
        gap: 10px;
        justify-content: end;

        a {
            display: inline-block;

            svg {
                width: 25px;
                height: 25px;
            }

        }
    }

    .copyright_p {
        font-weight: 400;
        color: $white;
        font-size: 14px;
        line-height: 21px;
    }
}