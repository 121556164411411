@import "./../../../SCSS/Mixins.scss";

.mhomepage-wrapper {
  padding-top: 114px;
  position: relative;
  z-index: 999;

  .product-section-header_m {
    padding-top: 24px;
    padding-bottom: 40px;

    h3 {
      color: rgba(0, 0, 0, 0.5);
    }

    p {
      color: rgba(0, 0, 0, 0.5);
    }

    .header-img {
      max-height: 150px;
    }

    .product-List {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      align-items: center;

      li {
        width: calc((100% / 3) - 16px);
        text-align: center;

        img {
          transition: transform 0.6s linear;
          transform-style: preserve-3d;
          transform-origin: center center;
          width: 60px;
          margin: auto;
        }

        &:hover {
          img {
            transform: rotateY(180deg);
          }
        }

        a {
          font-family: "Signika", sans-serif;
          font-size: 15px;
          line-height: 23px;
          font-weight: 400;
          display: inline-flex;
          flex-direction: column;
          gap: 12px;
          color: $black;

          &:hover {
            color: #0a58ca;
          }
        }
      }
    }

    h6 {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .bluebg-section_m {
    background: $primary;
    padding: 16px 12px;

    .irda-sectionlist {
      display: flex;
      gap: 24px;
      flex-wrap: wrap;

      li {
        width: 100%;
        outline: 1px solid rgba(255, 255, 255, 0.2);
        outline-offset: -1px;
        border-radius: 140px;
        position: relative;
        min-height: 75px;
        padding: 10px 10px 10px 82px;
        display: flex;
        align-items: center;

        h6 {
          font-family: "Roboto";
          font-weight: 700;
          font-size: 20px;
          line-height: 23px;
          color: $white;
          margin-top: -1px;
          margin-bottom: 0px;

          @media (max-width: 900px) {
            font-size: 16px;
          }

          span {
            display: block;
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            padding-top: 2.5px;

            @media (max-width: 900px) {
              font-size: 14px;
            }
          }
        }

        $covers: google, office, irda, assistance;
        $k: 0;

        @each $icons in $covers {
          $bacRightP: $k * 74;
          $k: $k + 1;

          .#{$icons} {
            background: url("../../../../public/images/irda-imgs.svg")
              no-repeat -#{$bacRightP}px
              0px/296px;
            display: inline-block;
            height: 75px;
            width: 74px;
            position: absolute;
            top: 0px;
            left: 0px;
          }
        }
      }
    }
  }

  .achievementsbg_box_m {
    min-height: 208px;
    height: 100%;
    background: url("./../../../../public/images/achievements.png") no-repeat
      center top/200%;
    display: flex;
    align-items: center;
    justify-content: center;

    h3,
    h6 {
      color: $white;
    }

    h3 {
      font-size: 34px;
      line-height: 49px;
    }

    h6 {
      font-size: 16px;
      line-height: 28px;
      font-family: "roboto";
    }
  }

  .awrds_sec_m {
    p {
      font-size: 16px;
      line-height: 28px;
      font-family: "roboto";
      font-weight: 400;
      text-align: left;
    }

    img {
      max-height: 64px;
      margin: auto;
    }
  }

  .recent-stories-section_m {
    margin-top: 60px;
    margin-bottom: 70px;

    h3 {
      text-align: center;
      margin-bottom: 12px;
      font-weight: 400;
      font-size: 34px;
      line-height: 49px;
    }

    .recent_story_box {
      padding: 24px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
      border-radius: 16px;
      min-height: 540px;
      position: relative;

      .story-img {
        border-radius: 16px;
        width: 100%;
        object-fit: fill;
        height: 173px;
      }

      h5 {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 14px;
      }

      p {
        font-size: 16px;
        line-height: 28px;
        text-align: justify;
        margin-bottom: 24px;
      }

      .view_more {
        display: inline-flex;
        align-items: center;
        font-size: 14px;
        line-height: 14px;
        color: $primary;
        position: absolute;
        bottom: 24px;
        left: 24px;

        svg {
          height: 20px;
          width: 20px;
          margin-left: 2px;
        }
      }
    }

    .slick-dots {
      li {
        width: 8px;
        height: 8px;
        margin: 0px 4px;

        &.slick-active {
          button {
            background: $primary;
          }
        }

        button {
          width: 8px;
          height: 8px;
          background: rgba(227, 227, 227, 1);
          border-radius: 50%;

          &::before {
            display: none;
          }
        }
      }
    }
  }

  .why-choose-sec_m {
    h3 {
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 34px;
      line-height: 49px;
    }

    p {
      font-size: 16px;
    }

    .wc_points {
      display: flex;
      flex-wrap: wrap;
      gap: 40px 24px;

      li {
        width: calc((100% / 2) - 12px);
        padding: 16px 8px;
        border-radius: 16px;
        text-align: center;

        span {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          background: linear-gradient(180deg, #0c4d85 0%, #73acdd 100%);
          width: 57px;
          height: 41px;
          border-radius: 8px;
          margin-bottom: 16px;

          img {
            width: 25px;
          }
        }

        h5 {
          font-size: 18px;
          line-height: 26px;
          font-weight: 400;
          margin-bottom: 15px;
        }

        p {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  .policy-issuance-section_m {
    .step_policy_box {
      padding: 9px 26px;
      border: 3px solid $primary;
      border-radius: 80px;
      max-width: 660px;
      display: inline-block;
      width: 100%;

      h3 {
        position: relative;
        padding-left: 45px;
        font-weight: 400;
        font-size: 34px;
        line-height: 49px;

        &:before {
          @include position(absolute, $top: 0, $left: 0);
          content: "";
          background: url("./../../../../public/images/fingers.svg") no-repeat 0
            0/34px;
          height: 59px;
          width: 34px;
        }
      }

      p {
        line-height: 28px;
        font-size: 16px;
      }
    }

    ul {
      margin-top: 24px;
      display: flex;
      flex-wrap: wrap;
      gap: 24px;

      li {
        // flex: 1;
        width: calc(50% - 12px);
        padding: 50px 12px 30px 12px;
        outline: 3px solid $white;
        outline-offset: -2px;
        border-radius: 32px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        margin-top: 46px;
        text-align: center;
        min-height: 236px;
        position: relative;

        &.one {
          background: $primary;
        }

        &.two {
          background: $secondary;
        }

        &.three {
          background: rgba(134, 23, 103, 1);
        }

        &.four {
          background: rgba(133, 75, 0, 1);
        }

        .step_icon {
          height: 40px;
          width: 40px;
          margin-bottom: 16px;
        }

        h5 {
          font-size: 18px;
          color: $white;
          font-family: "Signika";
          margin-bottom: 12px;
        }

        p {
          color: $white;
          font-size: 16px;
          line-height: 28px;
        }

        $producticons: oneImg, twoImg, threeImg, fourImg;
        $k: 0;

        @each $icons in $producticons {
          $bacRightP: $k * 78;
          $k: $k + 1;

          .#{$icons} {
            background: url("./../../../../public/images/onetwothreeforu.svg")
              no-repeat -#{$bacRightP}px
              0px/312px;

            display: block;
            position: absolute;
            top: -24px;
            left: calc(50% - 34px);
            width: 78px;
            height: 48px;
          }
        }
      }
    }
  }

  .partners-sec_m {
    padding: 24px 16px;
    background: rgba(231, 237, 243, 1);

    h3 {
      font-weight: 400;
      margin-bottom: 24px;
      text-align: center;
      font-size: 34px;
      line-height: 49px;
    }

    .partner_img {
      height: 60px;
      margin: auto;
    }

    .slick-dots {
      li {
        width: 8px;
        height: 8px;
        margin: 0px 4px;

        &.slick-active {
          button {
            background: $primary;
          }
        }

        button {
          width: 8px;
          height: 8px;
          background: rgba(227, 227, 227, 1);
          border-radius: 50%;

          &::before {
            display: none;
          }
        }
      }
    }
  }
}

// form popup checkbox
.popup-Checkbox {
  .checkbox {
    justify-content: center;

    .MuiTypography-root {
      font-size: 12px;
      color: $black;

      span {
        color: $primary;
      }
    }
  }
}
