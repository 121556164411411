@import "./../../../SCSS/Mixins.scss";

.homepage-wrapper {
    .product-section-header {
        padding-top: 80px;
        padding-bottom: 80px;
        min-height: 680px;
        position: relative;
        background: url("./../../../../public/images/header-background.jpg")repeat 0 0/100px;

        h1 {
            color: rgba(0, 0, 0, 0.53);
            margin-bottom: 10px;
        }

        .header_p {
            font-size: 18px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.56);
            font-weight: 400;
            margin-bottom: 50px;
        }

        .header-img {
            max-width: 258px;
            width: 100%;
        }

        .product-List {
            display: flex;
            flex-wrap: wrap;
            gap: 24px;
            align-items: center;

            li {
                width: calc((100% / 3) - 16px);
                text-align: center;

                img {
                    transition: transform 0.6s linear;
                    transform-style: preserve-3d;
                    transform-origin: center center;
                }

                &:hover {
                    img {
                        transform: rotateY(180deg);
                    }
                }

                a {
                    margin-top: 5px;
                    font-family: "Signika", sans-serif;
                    font-size: 15px;
                    line-height: 23px;
                    font-weight: 400;
                    display: inline-flex;
                    flex-direction: column;
                    gap: 12px;
                    color: $black;

                    &:hover {
                        color: #0a58ca;
                    }
                }
            }
        }

        .advisory-sec {
            display: flex;
            gap: 12px;
            align-items: center;
            margin-top: 50px;

            h4 {
                color: rgba(0, 0, 0, 0.53);
                margin-bottom: 12px;
                line-height: 32px;
            }
        }
    }

    .bluebg-section {
        background: $primary;
        padding: 40px;

        .irda-sectionlist {
            display: flex;
            gap: 24px;
            flex-wrap: wrap;
            justify-content: center;

            li {
                width: calc((100% / 3) - 16px);
                outline: 1px solid rgba(255, 255, 255, 0.2);
                outline-offset: -1px;
                border-radius: 140px;
                position: relative;
                min-height: 75px;
                padding: 10px 10px 10px 82px;
                display: flex;
                align-items: center;

                h6 {
                    font-family: "Roboto";
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 23px;
                    color: $white;
                    margin-top: -1px;
                    margin-bottom: 0px;

                    @media (max-width: 900px) {
                        font-size: 16px;
                    }

                    span {
                        display: block;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 28px;
                        padding-top: 2.5px;

                        @media (max-width: 900px) {
                            font-size: 14px;
                        }
                    }
                }


                $covers: google, office, irda, assistance;
                $k: 0;

                @each $icons in $covers {
                    $bacRightP: $k * 74;
                    $k: $k +1;

                    .#{$icons} {
                        background: url("../../../../public/images/irda-imgs.svg") no-repeat -#{$bacRightP}px 0px/296px;
                        display: inline-block;
                        height: 75px;
                        width: 74px;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                    }
                }
            }
        }
    }

    .achievementsbg_box {
        min-height: 420px;
        height: 100%;
        background: url('./../../../../public/images/achievements.png') no-repeat center top/100%;
        display: flex;
        align-items: center;
        justify-content: center;

        h1,
        h4 {
            color: $white;
        }

        h4 {
            font-size: 21px;
            line-height: 26px;
        }
    }

    .awrds_sec {
        p {
            font-size: 35px;
            line-height: 44px;
            font-family: "signika";
            font-weight: 300;
            text-align: left;
        }

        img {
            max-height: 162px;
            width: 100%;
        }
    }

    .recent-stories-section {
        margin-top: 130px;
        margin-bottom: 130px;

        h1 {
            text-align: center;
            margin-bottom: 24px;
            font-weight: 400;
        }

        .recent_story_box {
            padding: 24px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
            border-radius: 16px;
            min-height: 540px;
            position: relative;

            .story-img {
                border-radius: 16px;
                width: 100%;
                object-fit: fill;
                height: 173px;
            }

            h5 {
                font-size: 21px;
                line-height: 26px;
                margin-bottom: 14px;
            }

            p {
                font-size: 16px;
                line-height: 28px;
                text-align: justify;
                margin-bottom: 24px;
            }

            .view_more {
                display: inline-flex;
                align-items: center;
                font-size: 14px;
                line-height: 14px;
                color: $primary;
                position: absolute;
                bottom: 24px;
                left: 24px;

                svg {
                    height: 20px;
                    width: 20px;
                    margin-left: 2px;
                }
            }
        }

        .slick-dots {
            li {
                width: 14px;
                height: 14px;
                margin: 0px 4px;

                &.slick-active {
                    button {
                        background: $primary;
                    }
                }

                button {
                    width: 14px;
                    height: 14px;
                    background: rgba(227, 227, 227, 1);
                    border-radius: 50%;

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    .why-choose-sec {
        h1 {
            margin-bottom: 8px;
            font-weight: 400;
        }

        .wc_points {
            display: flex;
            flex-wrap: wrap;
            gap: 40px 24px;

            li {
                width: calc((100% / 3) - 16px);
                padding: 24px 16px;
                border-radius: 16px;
                text-align: center;
                transition: transform 0.3s ease-in-out;

                &:hover {
                    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
                    transform: translateY(-0.75rem);
                }

                span {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    background: linear-gradient(180deg, #0C4D85 0%, #73ACDD 100%);
                    width: 114px;
                    height: 82px;
                    border-radius: 8px;
                    margin-bottom: 16px;
                }

                h4 {
                    font-size: 21px;
                    line-height: 26px;
                    font-weight: 400;
                    margin-bottom: 16px;
                }
            }
        }
    }

    .policy-issuance-section {
        .step_policy_box {
            padding: 20px 80px;
            border: 6px solid $primary;
            border-radius: 80px;
            max-width: 660px;
            display: inline-block;
            width: 100%;

            h1 {
                position: relative;
                padding-left: 45px;
                font-weight: 400;

                &:before {
                    @include position(absolute, $top: 0, $left: 0);
                    content: "";
                    background: url("./../../../../public/images/fingers.svg") no-repeat 0 0/34px;
                    height: 59px;
                    width: 34px;
                }
            }

            p {
                line-height: 28px;
            }
        }

        ul {
            margin-top: 164px;
            display: flex;
            flex-wrap: wrap;
            gap: 57px;

            @media(max-width:1100px) {
                gap: 24px;
            }

            @media(max-width:1000px) {
                margin-top: 50px;
            }

            li {
                flex: 1;
                padding: 50px 12px 30px 12px;
                outline: 5px solid $white;
                outline-offset: -4px;
                border-radius: 32px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
                margin-top: 46px;
                text-align: center;
                min-height: 262px;
                min-width: 220px;

                @media(max-width:1000px) {
                    &:before {
                        display: none;
                    }
                }

                &.one {
                    background: $primary;
                    position: relative;

                    &:before {
                        @include position(absolute, $top: -275px, $left: calc(50% - 24px));
                        content: "";
                        background: url("./../../../../public/images/line1.svg") no-repeat 0 0/100%;
                        height: 251px;
                        width: 426px;
                    }
                }

                &.two {
                    background: $secondary;
                    position: relative;

                    &:before {
                        @include position(absolute, $top: -210px, $left: calc(50% - 24px));
                        content: "";
                        background: url("./../../../../public/images/line2.svg") no-repeat 0 0/100%;
                        height: 156px;
                        width: 192px;
                    }
                }

                &.three {
                    background: rgba(134, 23, 103, 1);
                    position: relative;

                    &:before {
                        @include position(absolute, $top: -210px, $right: calc(50% - 30px));
                        content: "";
                        background: url("./../../../../public/images/line3.svg") no-repeat 0 0/100%;
                        height: 156px;
                        width: 192px;
                    }
                }

                &.four {
                    background: rgba(133, 75, 0, 1);
                    position: relative;

                    &:before {
                        @include position(absolute, $top: -275px, $right: calc(50% - 30px));
                        content: "";
                        background: url("./../../../../public/images/line4.svg") no-repeat 0 0/100%;
                        height: 251px;
                        width: 426px;
                    }
                }

                .step_icon {
                    height: 70px;
                    width: 70px;
                    margin-bottom: 16px;
                }

                h5 {
                    font-size: 20px;
                    color: $white;
                    font-family: 'Roboto';
                    margin-bottom: 16px;
                }

                p {
                    color: $white;
                    font-size: 16px;
                    line-height: 28px;
                }

                $producticons: oneImg, twoImg, threeImg, fourImg;
                $k: 0;

                @each $icons in $producticons {
                    $bacRightP: $k * 120;
                    $k: $k +1;

                    .#{$icons} {
                        background: url("./../../../../public/images/onetwothreeforu.svg") no-repeat -#{$bacRightP}px 0px/480px;

                        display: block;
                        position: absolute;
                        top: -46px;
                        left: calc(50% - 60px);
                        width: 120px;
                        height: 78px;
                    }
                }
            }
        }
    }

    .partners-sec{
        padding: 24px 16px;
        background: rgba(231, 237, 243, 1);
        h1{
            font-weight: 400;
            margin-bottom: 24px;
            text-align: center;
        }
        .partner_img{
            height: 60px;
            margin: auto;
        }
    }
}

// form popup checkbox
.popup-Checkbox {
    .checkbox {
        justify-content: center;

        .MuiTypography-root {
            span {
                color: $primary;
            }
        }
    }
}

.self-selectField {
    .MuiToggleButtonGroup-root {
        height: 30px;

        button {
            font-size: 12px;
            line-height: normal;
            text-transform: capitalize;
            font-family: "Roboto", sans-serif !important;
            width: 60px;
            background: rgba(255, 243, 244, 1);
            font-weight: 400;
            color: rgba(79, 79, 79, 1);
            border: none;

            &.Mui-selected {
                background: $primary;
                color: $white;
            }
        }
    }
}