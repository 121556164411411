@import "../../SCSS/Mixins.scss";

.faq {
    margin-bottom: 8px;

    .MuiPaper-root {
        background: transparent;
        // @include box-shadow(0px 2px 0px #E3EDFF);
        // @include border-radius(12px!important);
        box-shadow: none;
        margin-bottom: 8px;

        &:last-child {
            border-bottom: none;
        }

        &:before {
            display: none;
        }
        &.Mui-expanded{
            margin-top: 0;
            margin-bottom: 8px;
        }
    }

    .MuiAccordionSummary-root {
        padding: 0;
        min-height: 24px;
        // max-height: 44px;

        svg {
            transition: all 0.3s ease-in-out;
            // position: absolute;
            // left: -1px;
        }

        &.Mui-expanded {
            min-height: 24px;

            // max-height: 44px;
            svg {
                transform: rotate(-180deg);
                transition: all 0.3s ease-in-out;
                color: $secondary;
            }
        }

        .MuiAccordionSummary-expandIconWrapper {
            color: $primary;
            transform: none;
            position: absolute;
            left: -1px;

            svg {
                height: 18px;
                width: 18px;
            }

        }
    }

    .MuiAccordionSummary-content {
        margin: 0px;

        &.Mui-expanded {
            margin: 0px;
        }

        p {
            @include fontSize(16px);
            font-family: 'Roboto';
            line-height: 28px;
            font-weight: 700;
            color: $black;
            padding-left: 22px;

            // @media(max-width:899px) {
            //     @include fontSize(16px);
            //     line-height: 20px;
            // }
        }
    }

    .MuiAccordionDetails-root {
        padding: 0px 0px 16px;
        background: #e7edf3;
        border-radius: 4px;
        padding: 8px;

        p {
            @include fontSize(16px);
            font-family: 'Roboto';
            line-height: 28px;
            text-align: justify;
        }
    }
}