@import "./../../../../SCSS/Mixins.scss";

.navbar {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background: $white;
    box-shadow: 5px 5px 20px 0px rgba(34, 34, 34, 0.2);
    z-index: 99;

    .top-nav {
        padding: 8px 0px;

        .blinker_a {
            animation: blinker 2s linear infinite;
        }

        @keyframes blinker {
            50% {
                opacity: 0;
            }
        }
    }

    .bottom-nav {
        background: $primary;
        padding: 15px 0px;

        ul {
            display: flex;
            justify-content: flex-end;
            gap: 24px;
            align-items: center;

            li {

                a {
                    @include fontSize(14px);
                    line-height: 21px;
                    color: $white;
                    font-weight: 400;
                    position: relative;
                    display: inline-block;

                    &:hover {
                        color: $secondary;
                        opacity: 1;

                    }


                }

                .primaryBtn {
                    button {
                        padding: 6px 12px 6px 20px !important;
                    }
                }
            }
        }
    }
}