@import "./SCSS/FontStyle.scss";
@import url('https://fonts.googleapis.com/css2?family=Signika:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Signika+Negative:wght@300..700&display=swap');
@import "./Component/InputFields/FieldTypes.scss";

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-rendering: auto;
  text-rendering: optimizeSpeed;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
  font-synthesis: none;
  -moz-font-feature-settings: "kern";
  -moz-osx-font-smoothing: grayscale;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-size: 14px;
}

a {
  cursor: pointer;
  text-decoration: none !important;
}

img {
  vertical-align: middle;
}

// .MuiModal-root .MuiModal-backdrop {
//   background-color: rgba(0, 0, 0, 0.15);
//   backdrop-filter: blur(3px);
// }

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

.row {
  max-width: 1224px;
  width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
}

.MuiPickersToolbar-content {
  display: none !important;
}